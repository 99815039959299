<template>
  <div>
    <div class="mb-4 d-flex justify-content-end">
      <button
        class="btn btn-primary d-flex"
        @click="showNewReportTypeModal = true"
      >
        <i class="material-icons-outlined">add</i>
        <span class="ml-2"> New Report Type</span>
      </button>
    </div>
    <div>
      <div class="card row"></div>
      <card-table-header :headers="fields"></card-table-header>
      <cardTableBody
        :allItemsCount="reports.length"
        :items="reports"
        :page="1"
        :pages="1"
        :loading="isLoadingReportList"
      >
        <div
          class="
            card
            row
            p-3
            d-flex
            flex-row
            justify-content-sm-end justify-content-xl-start
          "
          v-for="(item, i) in reports"
          :key="i"
        >
          <div class="col-2 col-lg-1">{{ item.id }}</div>
          <div class="col-7 col-lg-4 text-primary">
            <router-link :to="`/reports/${item.id}`">
              <div class="mx-2">{{ item.description }}</div>
            </router-link>
          </div>
          <div class="col-3 col-lg-2">
            <span
              :class="`btn btn-xs btn-block btn-${
                item.isActive === 1 ? 'primary' : 'danger'
              }`"
              >{{ item.isActive === 1 ? "On" : "Off" }}</span
            >
          </div>
          <div class="col-12 col-lg-5 mt-4 mt-lg-0">
            <router-link :to="`/reports/${item.id}`"
              ><button class="btn btn-info mx-2 btn-xs">
                Question Groups of the Report
              </button></router-link
            >
            <button
              class="btn btn-warning mx-2 btn-xs"
              @click="
                editReportType = { ...item, index: i };
                showModal = true;
              "
            >
              <i style="font-size: 14px" class="material-icons-outlined mr-2"
                >border_color</i
              >Update
            </button>
          </div>
        </div>
      </cardTableBody>
    </div>
    <modal
      okText="Save"
      title="New Report Type"
      v-model="showNewReportTypeModal"
      @ok="SEND_NEW_REPORT_TO_API"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="newReportType.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Report Name"
        />
      </div>
    </modal>
    <modal
      @ok="SEND_UPDATE_REPORT_TO_API"
      okText="Save Changes"
      title="Update Report Type"
      v-model="showModal"
    >
      <div
        class="alert text-center"
        v-if="alert.status"
        :class="`alert-${this.alert.variant}`"
      >
        {{ alert.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          id="email"
          maxlength="50"
          v-model="editReportType.description"
          required
          aria-describedby="emailHelp"
          placeholder="Enter Report Name"
        />
      </div>
      <div class="mt-4">
        <strong>Situation: </strong>
        <button
          @click="editReportType.isActive = 1"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editReportType.isActive === 0,
            'btn-primary': editReportType.isActive === 1,
          }"
        >
          Active
        </button>
        <button
          @click="editReportType.isActive = 0"
          class="btn btn-xs mx-2"
          :class="{
            'btn-secondary': editReportType.isActive === 0,
            'btn-danger': editReportType.isActive === 0,
          }"
        >
          Archived
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import GetReportType from "./getReportType";
import cardTableHeader from "../ui/table/cardTableHeader.vue";
import cardTableBody from "../ui/table/cardTableBody.vue";
import Modal from "../ui/modal.vue";
export default {
  mixins: [GetReportType],
  data() {
    return {
      alert: {
        status: false,
        variant: "success",
        message: "",
      },
      newReportType: { description: "", isActive: 1 },
      showNewReportTypeModal: false,
      showModal: false,
      editReportType: {},
      fields: [
        { label: "Id", sort: false, col: 12, md: 6, lg: 1, xl: 1 },
        { label: "Name", sort: false, col: 12, md: 6, lg: 4, xl: 4 },
        { label: "Situation", sort: false, col: 12, md: 6, lg: 2, xl: 2 },
        { label: "Actions", sort: false, col: 12, md: 6, lg: 5, xl: 5 },
      ],
    };
  },
  components: {
    Modal,
    cardTableHeader,
    cardTableBody,
  },
  methods: {
    async SEND_NEW_REPORT_TO_API(e, loading) {
      this.alert.status = false;
      if (this.newReportType.description === "") return;
      loading();
      const response = await this.$api.post("Reports", {
        description: this.newReportType.description,
        isActive: 1,
      });
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Report updated successfully",
        };
        this.GET_INITIAL_REPORT_LIST(1,10, true);
        setTimeout(() => {
          this.showNewReportTypeModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
    async SEND_UPDATE_REPORT_TO_API(e, loading) {
      this.alert.status = false;
      if (this.editReportType.description === "") return;
      loading();
      const response = await this.$api.put(
        `Reports/${this.editReportType.id}`,
        {
          description: this.editReportType.description,
          isActive: this.editReportType.isActive,
        }
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alert = {
          status: true,
          variant: "success",
          message: "Report saved successfully",
        };
        this.GET_INITIAL_REPORT_LIST(1,10, true);
        setTimeout(() => {
          this.showModal = false;
          this.alert.status = false;
        }, 1000);
      } else {
        this.alert = {
          status: true,
          variant: "danger",
          message: "Something went wrong. Please Try Again",
        };
      }
      loading(false);
    },
  },
};
</script>

<style>
</style>